
.container {
	width: 100%;
}

.row {
	flex-direction: row;

	&.reverse {
		flex-direction: row-reverse;
	}

}

.flex-column {
	flex-direction: column;

	&.reverse {
		flex-direction: column-reverse;
	}
}

.fill {
	flex-grow: 1;
}

.center-content {
	justify-content: center;
}

.row, .flex-column {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    &.no-wrap {
      flex-wrap: nowrap;
    }

    [class^="col-"] {
        box-sizing: border-box;

    }
}

.makeGrid(@counter) when (@counter > 0) {
  	@width: (@counter / @columns ) * 100;
  	.col-@{counter} {
    	flex-basis: ~"@{width}%";
    	max-width: ~"@{width}%";
    	flex-grow: 0;
        flex-shrink: 0;
  	}

  	.col-offset-@{counter} {
    	margin-left: ~"@{width}%";
  	}

  	.makeGrid((@counter - 1));
}

.makeGrid(@columns);



.hidden-sm {
	display: none;
}


@media (min-width: @screenTablet) {
	.makeGrid(@counter) when (@counter > 0) {
  		@width: (@counter / @columns ) * 100;
	  	.col-tb-@{counter} {
	    	flex-basis: ~"@{width}%";
	    	max-width: ~"@{width}%";
	    	flex-grow: 0;
        	flex-shrink: 0;
	  	}

	  	.col-offset-tb-@{counter} {
	    	margin-left: ~"@{width}%";
	  	}

  	.makeGrid((@counter - 1));
	}

	.makeGrid(@columns);

	.makeFluidGrid(@counter) when (@counter > 0) {
  		@width: (@counter / @columns ) * 100;
  		@totalGutter: @gutter;
	  	.col-fl-tb-@{counter} {
	    	flex-basis: ~"calc(@{width}% - @{totalGutter})";
	    	max-width: ~"calc(@{width}% - @{totalGutter})";
	    	flex-grow: 0;
        	flex-shrink: 0;
        	margin-left: @gutter;
        	margin-right: @gutter;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

	  	}

	  	.col-fl-offset-tb-@{counter} {
	    	margin-left: ~"@{width}%";
	  	}

  		.makeFluidGrid((@counter - 1));
	}

	.makeFluidGrid(@columns);

	.hidden-sm

	.hidden-tb {
		display: none;
	}
}

@media (min-width: @screenDesktop) {

	.container {
		width: @screenDesktop;
		margin: 0 auto;
	}



	.makeGrid(@counter) when (@counter > 0) {
  		@width: ((@screenDesktop / @columns) * @counter) - @gutter * 2;
	  	.col-dt-@{counter} {
	    	flex-basis: @width;
	    	max-width: @width;
	    	margin: 0 @gutter;
	    	flex-grow: 0;
        	flex-shrink: 0;
	  	}

	  	.col-offset-dt-@{counter} {
	    	margin-left: @width;
	  	}

  	.makeGrid((@counter - 1));
	}

	.makeGrid(@columns);

	.makeFluidGrid(@counter) when (@counter > 0) {
  		@width: (@counter / @columns ) * 100;
  		@totalGutter: @gutter;
	  	.col-fl-dt-@{counter} {
	    	flex-basis: ~"calc(@{width}% - @{totalGutter})";
	    	max-width: ~"calc(@{width}% - @{totalGutter})";
	    	flex-grow: 0;
        	flex-shrink: 0;
        	margin-left: @gutter;
        	margin-right: @gutter;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

	  	}

	  	.col-fl-offset-dt-@{counter} {
	    	margin-left: ~"@{width}%";
	  	}

  		.makeFluidGrid((@counter - 1));
	}

	.makeFluidGrid(@columns);

	.hidden-dt {
		display: none !important;
	}

}

@media (min-width: @screenMidSizeDesktop) {

	.container {
		width: @screenMidSizeDesktop;
		margin: 0 auto;
	}



	.makeGrid(@counter) when (@counter > 0) {
  		@width: ((@screenMidSizeDesktop / @columns) * @counter) - @gutter * 2;
	  	.col-msdt-@{counter} {
	    	flex-basis: @width;
	    	max-width: @width;
	    	margin: 0 @gutter;
	    	flex-grow: 0;
        	flex-shrink: 0;
	  	}

	  	.col-offset-msdt-@{counter} {
	    	margin-left: @width;
	  	}

  	.makeGrid((@counter - 1));
	}

	.makeGrid(@columns);

	.makeFluidGrid(@counter) when (@counter > 0) {
  		@width: (@counter / @columns ) * 100;
  		@totalGutter: @gutter;
	  	.col-fl-msdt-@{counter} {
	    	flex-basis: ~"calc(@{width}% - @{totalGutter})";
	    	max-width: ~"calc(@{width}% - @{totalGutter})";
	    	flex-grow: 0;
        	flex-shrink: 0;
        	margin-left: @gutter;
        	margin-right: @gutter;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

	  	}

	  	.col-fl-offset-msdt-@{counter} {
	    	margin-left: ~"@{width}%";
	  	}

  		.makeFluidGrid((@counter - 1));
	}

	.makeFluidGrid(@columns);

	.hidden-msdt {
		display: none !important;
	}

}


@media (min-width: @screenLarge) {
	.container {
		width: @screenLarge;
		margin: 0 auto;
	}

	.makeGrid(@counter) when (@counter > 0) {
  		@width: ((@screenLarge / @columns) * @counter) - @gutter * 2;
	  	.col-lg-@{counter} {
	    	flex-basis: @width;
	    	max-width: @width;
	    	margin-left: @gutter;
	    	margin-right: @gutter;
	    	flex-grow: 0;
        	flex-shrink: 0;
	  	}

	  	.col-offset-lg-@{counter} {
	    	margin-left: @width;
	  	}

  	.makeGrid((@counter - 1));
	}

	.makeGrid(@columns);

	.makeFluidGrid(@counter) when (@counter > 0) {
  		@width: (@counter / @columns ) * 100;
  		@totalGutter: @gutter ;
	  	.col-fl-lg-@{counter} {
	    	flex-basis: ~"calc(@{width}% - @{totalGutter})";
	    	// max-width: ~"@{width}%";
	    	flex-grow: 0;
        	flex-shrink: 0;
        	margin-left: @gutter;
        	margin-right: @gutter;

        	&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
	  	}

	  	.col-fl-offset-lg-@{counter} {
	    	margin-left: ~"@{width}%";
	  	}

  	.makeFluidGrid((@counter - 1));
	}

	.makeFluidGrid(@columns);

	.hidden-lg {
		display: none !important;
	}

	.hidden-sm {
		display: block;
	}
}
