.figure {
    background: darken(#f2f2f2, 10%);
    padding: 5px;
    margin: 20px 0;
    figure {
        margin: 0;

        figcaption {
            padding: 10px;

            p {
                padding: 10px;
            }
        }

        .figure-head {
            margin-bottom: 20px;
            padding: 10px 10px;
        }

        &.under figcaption {
            margin: 20px 0;
        }

        &.overlay {
        	position: relative;

            figcaption {
                position: absolute;
                bottom: 0px;
                width: 100%;
                padding: 10px;
            }

            &.dark figcaption {
                color: #fff;
                background: rgba(0, 0, 0, 0.75);
                font-style: italic;
            }

            &.light figcaption {
                color: #000;
                background: rgba(255, 255, 255, 0.75);
            }
        }

        .image-center-wrapper {
            display: table;
            margin: 0 auto;

            img {
                padding: 5px;
                border: 1px solid #ccc;
                box-sizing: border-box;


            }
        }

    }

}
