


.confluence-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 50;
    color: @linkColor;
    cursor: pointer;
    transition: margin-right 0.5s;
    z-index: 1001;

    &.open {
        margin-right: 285px;
        transition: margin-right 0.5s
    }
}


.confluence-menu {
    position: fixed;
    top: 0;
    right: -400px;
    width: 350px;
    height: 100%;
    box-sizing: border-box;
    background-color: #efefef;
    box-shadow: -2px 0px 5px 2px rgba(0, 0, 0, 0.15);
    transition: right 0.5s;
    z-index: 1000;

    &.open {
        right: 0;
        transition: right 0.5s;
    }

    h2 {
        padding: 20px;
    }

    .confluence-menu-header {
        background-color: #efefef;
        padding: 20px;
        border-bottom: 1px solid @medium-gray;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;

        h2 {
            padding: 0;
            margin-left: 40px;
        }
    }

    .confluence-menu-links {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: 1px solid @light-gray;

            &:first-child {
                border-top: 1px solid @light-gray;
            }

            a {
                display: block;
                padding: 20px;
                text-decoration: none;

                &:hover {
                    background-color: @linkColor;
                    color: @white;
                }

            }

        }
    }
}
