.list-reset() {
	list-style: none;
	margin: 0;
	padding: 0;
	
}

.drop-shadow(@alpha: 0.14) {
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, @alpha);
}

.font-light() {
	font-family: @font;
	font-weight: 300;
}

.font-normal() {
	font-family: @font;
	font-weight: 400;
}

.font-medium() {
	font-family: @font;
	font-weight: 700;
}

.font-bold() {
	font-family: @font;
	font-weight: 900;
}