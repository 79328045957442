/**************************************************
                    COLORS
**************************************************/

/****************** CORE *************************/
@primary: #63687D;
@secondary: #9995A9;
@accent: #D4D2DA;

/***************** TINTS & SHADES ****************/
@dark: #9C7D64;
@medium: #BDA794;
@light: #f4f5f7;
@nav-header: @nav-bg;

/**************** GRAYS **************************/
@black: #000000;
@dark-gray: #252525;
@medium-gray: #505050;
@light-gray: #b3b3b3;
@white: #FFFFFF;

/***************** MENU *************************/
@nav-bg: #2c2c29; 

/******************* FONT COLORS *****************/

@pageTreeLink: #0645AD;
@gold: #ebab2f;
@linkColor: #0052CC;
@linkVisitedColor: darken(@linkColor, 10%);

/************************************** NOTE BOX ******************************/
@note-color: @white;
@note-border: #efb100;

/**************************************************
 *                 TYPOGRAPHY                     *
 **************************************************/

@font: 'Lato', sans-serif;
@font-size: 16px;
@line-height: 1.5;

@serif: 'Libre Baskerville', serif;

@title-size: 32px;
@heading1-size: 25px;
@heading2-size: 20px;
@heading3-size: 17px;
@heading4-size: 15px;
@heading5-size: 14px;
@heading6-size: 12px;
@heading1-weight: 600;



/**************************************************
 *                  FLEX GRID LAYOUT              *
 **************************************************/

// Grid
@columns: 12;
@gutter: 20px;

// Break-points
@screenTablet: 	768px;
@screenDesktop: 1100px;
@screenMidSizeDesktop: 1240px;
@screenLarge:   1440px;

@side-nav-mobile-width: 100%;
@side-nav-mobile-height: 40px;

