.header-container {
	width: 100%;
	background-color: @black;
}

header {
	background: url(../assets/img/webb-logo.png) no-repeat;
	background-size: 150px 80px;
	background-color: @black;
	background-position: left top;
	height: 110px;

	.main-title {
		.font-medium();
		color: @gold;
		font-size: 32px;
		text-shadow: 2px 2px @dark-gray;
		border-bottom: 0;
		padding: 20px 10px;
		text-align: right;

		span {
			display: block;
			.font-light();
		}
	}
}

@media (min-width: @screenTablet) {
    header {
		height: 197px;
		background-position: left bottom;
		background-size:contain;
		.main-title {
			font-size: 32px;
			margin: 0px 0 0 350px;
			padding-top: 50px;
		}
	}
}

@media (min-width: @screenDesktop) {

}

@media (min-width: @screenLarge) {
	header {


		.main-title {
			font-size: 44px;
			margin: 0px 0 0 350px;
			padding-top: 40px;
		}
	}
}