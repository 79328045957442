.top-nav-container {
	width: 100%;
	background-color: @nav-bg;
}

.top-nav {
	color: @gold;
	margin-bottom: 40px;

	&.sticky {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;
	}

	&.sticky + .content {
		padding-top: 90px;
	}



	ul {
		.list-reset();
		display: flex;
        padding-left: 30px;

		li {

			&.tablet-nav {
				display: none;
			}

			display: inline-block;
			padding: 0;
			margin: 0;

			position: relative;

			.aui-icon-small {
				&:before {
		    		margin-top: 0px;
				}
			}

			a, div {
				color: @gold;
				padding: 10px;
				display: block;
				.font-medium();

				&:hover {
					background-color: @medium-gray;
					text-decoration: none;
				}
			}

			&:hover ul {
				display: flex;
			}

			ul {
				display: none;
				position: absolute;
				background-color: @dark-gray;
				width: 250px;
				z-index: 100;

				li {
					a {
						padding: 5px 10px;
					}
				}
			}
		}
	}


	.search-form {
		padding: 10px;
		display: flex;
		background-color: @nav-bg;
		position: relative;

        .search-box {
            border: 1px solid @gold;
            width: 80%;
            margin: 0 auto;
            border-radius: 25px;
        }

		input {
			background-color: transparent;
			border: none;
			padding: 5px 5px 5px 10px;
			color: @dark-gray;
			width: 200px;
            margin-bottom: 5px;
			font-size: @font-size;

			&::placeholder {
				color: @gold;
			}

			&:focus {
				outline: @accent;
			}
		}

		.aui-icon-large {
			height: 24px;
			width: 24px;

			&:before {
				color: @gold;
	    		font-size: 24px;
	    		margin-top: 3px;
			}
		}

		.quick-search {
			display: none;
			position: absolute;
			z-index: 1000;
			top: 51px;
			right: 63px;
			width: 248px;
			background-color: @nav-bg;
			border-top: 1px solid @gold;

			&.show {
				display: block;
			}

			ul {
				flex-direction: column;

				li {

					a {
						padding: 7px 12px;
						cursor: pointer;
						background-position: 2% 50%;

						&:hover {
							background-color: @primary;
						}

						span {
							padding: 0 0 0 24px;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}


			}
		}

	}
}

@media (min-width: @screenTablet) {

	.top-nav {

		.tb-center {
			justify-content: center;
		}

        ul {
			
            padding-left: 0;
            li {

				&.tablet-nav {
					display: inline-block;
				}

                a {
                    padding: 25px;
                    text-align: center;

                    
                }
            }
        }

		.search-form {
			margin-top: 4px;
			margin-bottom: 4px;
			margin-right: 4px;
			margin-left: 0;

            input {
                margin-top: 12px;
            }

			.aui-icon-large:before {
	    		margin-top: 5px;
			}

			.quick-search {
				width: 68%;
                top: 56px;
                right: 75px;
                border-top: 1px solid @gold;
			}
		}
	}

}

@media (min-width: @screenDesktop) {
	.top-nav {
		.tb-center {
			justify-content: normal;
		}

		ul {
			li {
				&.tablet-nav {
					display: none;
				}
			}
		}

		.search-form {

			.quick-search {
				width: 71%;
                top: 56px;
                right: 88px;
                border-top: 1px solid @gold;
			}
		}


		
	}
}

@media (min-width: @screenLarge) {

	.top-nav {

		ul {
			
            padding-left: 0;
            li {
                a {
                    padding: 30px;
                    text-align: center;

                    
                }
            }
        }
		.search-form {
			margin-left: 510px;
			.quick-search {
				width: 65%;
                top: 65px;
                right: 73px;
                border-top: 1px solid @gold;
			}
		}
	}

}

