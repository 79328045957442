* {
	box-sizing: border-box;
}
body {
	background-color: @light;
}



.version-warning {
	margin-bottom: 20px;
	display: none;
}

.side-nav-mobile {
	position: fixed;
	bottom: 0;
	left: 0;
	width: @side-nav-mobile-width;
	height: @side-nav-mobile-height;
	display: flex;
	background-color: @gold;
	.drop-shadow(0.25);
	z-index: 199;
	justify-items: center;

	.sidebar-menu {
		color: @nav-bg;
		padding: 2px;
		align-self: center;
		display: flex;
		justify-content: center;
		align-items: center;
		width: @side-nav-mobile-width;
		height: @side-nav-mobile-height;

		span {
			padding-right: 20px;
		}
	}
}

.sidenav {
	background-color: @white;
	position: fixed;
	left: -100%;
	top: 0;
	transition: left 0.5s;
	height: 100%;
	overflow-y: scroll;
	align-self: flex-start;
	.drop-shadow();

	

	&.open {
		width: 100%;
		left: 0;
		transition: left 0.5s;
		z-index: 200;
	}

	.close-sidebar {
		color: @gold;
		text-decoration: none;
		position: absolute;
		top: 10px;
		right: 20px;
		z-index: 500;
	}

	.nav-section-header {
		background-color: @nav-header;
		padding: 15px;
		.font-bold();
		letter-spacing: 0.4pt;
		color: @white;
	}

	ul.pageTree {
		li {
			padding: 3px;
		}
		a {
			color: @dark-gray;
			transition: color 0.5s;
			.font-medium();

			&:hover {
				text-decoration: none;
				color: @gold;
				transition: color 0.2s;
			}
			

			&.toggleChildren {
				color: @gold;
				top: 5px;
			}
		}

		span {
			&.no-children {
				color: @gold;
			}

		}

	}
	



}

.prop-banner {
	background-color: @secondary;
	color: @white;
	padding: @gutter;
	.drop-shadow();

	a {
		color: @white;
		text-decoration: underline;
	}
}

.main-content {
	background-color: @white;
	padding: @gutter;
	padding-left: 25px;
	margin-bottom: 20px;
	.drop-shadow();

	&.home {
		padding: 60px;
	}

	table {
		&.confluenceTable {
			th {
				&.confluenceTh {
					background-color: #eee;
					> p {
						background-color: #eee;
					}
				}
			}
		}
	}

	.breadcrumbs {
		display: none;
	}
	.page-title {
		position: relative;
		min-height: 60px;
		margin-bottom: 20px;


		h1 {
			margin-top: 0;
			padding: 10px;
		}

		.section-icon {
			// position: absolute;
			// right: 10px;
			// top: 20px;
			// width: 25%;
			margin-bottom: 20px;

			.icon-link {
				display: none;
				font-size: 12px;

				span {
					font-size: 14px;
					font-weight: bold;
					margin-bottom: 10px;

				}
			}

			img {
				&.section-image {
					// float: left;
					margin-right: 10px;
				}
			}
		}

	}

	img, table {
		max-width: 100%;
	}
}

.labels {
	display: none;
	float: right;
	text-align: right;
	padding-left: 30px;

	div {
		display: inline-block;
		margin: 3px;
		padding: 3px;
	}
	
	ul {
		.list-reset();
		display: flex;
		flex-wrap: wrap;

		li {
			margin: 3px;

			a {
				display: block;
				padding: 3px;

				&:hover {
					text-decoration: none;
					background-color: darken(@accent, 5%);
				}
			}
		}
	}
}

.landing-page-sections {
	.landing-section {
		background: @white;
		padding: @gutter;
		margin-bottom: @gutter;

		&::first-child {
			margin-left: 0;
		}

		&::last-child {
			margin-right: 0;
		}
	}
}

.footer-container {
	width: 100%;
	background-color: @nav-bg;
}

footer {
	
	margin-top: 20px !important;
	justify-content: center;
	color: @white;
	padding: 20px;
	text-align: center;

	img {
		width: 80%;
		// height: 250px;
		margin: 0px auto;
		display: block;
	}

	a, a:visited, a:focus, a:hover, a:active {
		color: @gold !important;
	}

	.logo {
		// display: flex;
		order: 1;
	}

	.home-link {
		text-align: center;
		order: 0;
		a {
			font-size: 24px;
		}
	}

	.other-links {
		order: 1;
		text-align: center;
		display: flex;
		flex-wrap: wrap;

		a {
			display: block;
			width: 100%;
			padding: 5px;
		}
	}

	.social-links {
		order: 2;
	}

	.nasa-link {
		order: 3;
		img {
			width: 50%;
		}

	}

	.esa-link {
		order: 4;
		img {
			width: 50%;
			margin-top: 25px;
		}
	}

	.csa-link {
		order: 5;

		img {
			width: 50%;
		}
	}

	.jwst-blurb {
		order: 0;
	}

	.copyright {
		order: 1;
	}

	.report-button {
		order: 2;
	}

	.social-icon {
	  padding: 10px;
	  font-size: 18px;
	  width: 50px;
	  height: 50px;
	  text-align: center;
	  text-decoration: none;
	  margin: 5px;
	  background-size: cover;
	  display: inline-block;

	  	&:hover {
			opacity: 0.7;
	  	}


		&.facebook {
			background: url(../assets/img/facebook.svg) no-repeat;
		}

		&.twitter {
			background: url(../assets/img/twitter.svg) no-repeat;
		}

		&.youtube {
			background: url(../assets/img/youtube.svg) no-repeat;
		}


	}

	.aura-attribution {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid #f2f2f2;
	}

	


	.fa-facebook {
	  background: #3B5998;
	  color: @white;
	}

	.fa-twitter {
	  background: #55ACEE;
	  color: @white;

	}

	.fa-youtube {
	  background: #bb0000;
	  color: @white;

	}
}


@media (min-width: @screenTablet) {
	

	.side-nav-mobile {
		height: @side-nav-mobile-height + 20px;

	
		.sidebar-menu {
			font-size: 18px;
			height: @side-nav-mobile-height + 20px;
	
			span {
				padding-right: 20px;
			}
		}
	}

	.main-content {
		.breadcrumbs {
			.list-reset();
			display: block;
			font-size: 12px;

			li {
				display: inline-block;

				&:first-child::before {
					content: '';
				}

				&::before {
					content: '/';
					padding: 0 4px;
				}
				a {
					color: @dark-gray;
				}
			}
		}

		.page-title {
			.section-icon {

				.icon-link {
					display: block;
					font-size: 12px;

					span {
						font-size: 14px;
						font-weight: bold;
						margin-bottom: 10px;

					}
				}

				.section-icon-image {
					overflow: auto;
					img {
						&.section-image {

							margin-right: 10px;
						}
					}
				}

			}
		}

	}

	.sidenav {
		&.open {
			width: 50%;
			.drop-shadow(0.25);
		}
	}

	footer {
		
		.logo {
			order: 0;
		}

		.other-links {
			a {
				width: 33%;
				box-sizing: border-box;
				display: inline-block;
			}
		}
	}
}

@media (min-width: @screenDesktop) {
	.sidenav {
		position: static;
		transition: 0.5s;
		left: 0;
		top: 0;
		height: auto;
		overflow-y: auto;

	}

	footer {
		padding: 20px 0;
		.jwst-blurb {
			order: 3;
		}

		.row {
			margin-top: 20px;
		}
	}
}

@media (min-width: @screenLarge) {


}
