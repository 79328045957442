@import 'https://fonts.googleapis.com/css?family=Lato|Libre+Baskerville';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
	font-family: @font;
	font-size: @font-size;
	line-height: @line-height;
}

h1 {
	font-family: @font;
	font-size: @heading1-size;
	font-weight: @heading1-weight;
}

a {
	color: @linkColor;

	&:visited {
		color: @linkVisitedColor;
	}
}

ul {
	&.pageTree {
		font-size: @font-size * 0.85;
	}
}

.figure {
	font-family: @serif;

	figure {
		.figure-head {
			font-size: @font-size * 0.85;
			font-weight: 700;
		}

		figcaption {
			font-size: @font-size * 0.85;
		}
	}
}

.attributionLine {
	font-size: @font-size * 0.65;
	color: @light-gray;
	text-align: center;

	.scroll-viewport-attribution {
		display: inline-block;
		width: 100%;
	}
}

.confluence-information-macro {
	margin: 20px 0;
}

.confluence-information-macro-note {
	background-color: @note-color;
	border-color: @note-border;
}

.toc-macro {
	ul {
		margin-top: 0;
		margin-bottom: 0;
	}
}


table {
	font-size: 14px;
}

h1.pageTitle {
	font-size: @title-size;

}

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
}

h1 {
	font-size: @heading1-size;
}

h2 {
	font-size: @heading2-size;
}

h3 {
	font-size: @heading3-size;
}

h4 {
	font-size: @heading4-size;
}

h5 {
	font-size: @heading5-size;
}

h6 {
	font-size: @heading6-size;
}
